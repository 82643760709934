import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  projectLinksArray,
  investorLinksArray,
  StaticLinksArray,
} from "./MockSidebar";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import { useSelector, UseSelector } from "react-redux";
import { useDispatch } from "react-redux";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setLogOutModal: (arg: boolean) => void;
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setLogOutModal,
}: SidebarProps) => {
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);
  const navigate = useNavigate();
  const user = useSelector((store: any) => store.user.user);

  const storedActiveSidebar = localStorage.getItem("activeSidebar");
  const [activeSidebar, setActiveSidebar] = useState(
    user?.isProjectManager
      ? storedActiveSidebar
        ? storedActiveSidebar
        : "project"
      : "project",
  );

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === "true",
  );

  useEffect(() => {
    localStorage.setItem("activeSidebar", activeSidebar);
  }, [activeSidebar]);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    document.body.classList.toggle("sidebar-expanded", sidebarExpanded);
  }, [sidebarExpanded]);

  const handleToggle = () => {
    const newSidebarState =
      activeSidebar === "project" ? "investor" : "project";
    setActiveSidebar(newSidebarState);
    navigate(newSidebarState === "project" ? "/" : "/projectoverview");
  };

  const clickHandler = (name: string) => {
    if (name.toLocaleLowerCase() === "logout") {
      setLogOutModal(true);
    }
  };

  const renderLinks = (linksArray: any[]) => (
    <>
      {linksArray.map((linkItem, index) => {
        if (linkItem.name === "Logout") {
          return (
            <button
              key={index}
              onClick={(e) => {
                clickHandler(linkItem.name);
                e.stopPropagation();
              }}
              className="link-button px-4 py-[18px] rounded-[10px] text-white"
            >
              <div className="flex gap-6 items-center">
                {linkItem.img}
                <p className="text-white text-[15px]">{linkItem.name}</p>
              </div>
            </button>
          );
        }

        return (
          <NavLink
            key={index}
            to={linkItem.link}
            className={({ isActive }) =>
              `link-button px-4 py-[18px] rounded-[10px] ${
                isActive ? "bg-[#5dcbba]" : ""
              }`
            }
            onClick={(e) => {
              clickHandler(linkItem.name);
              e.stopPropagation();
            }}
          >
            <div className="flex gap-6 items-center">
              {linkItem.img}
              <p className="text-white text-[15px]">{linkItem.name}</p>
            </div>
          </NavLink>
        );
      })}
    </>
  );
  // scroll to add class
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={`w-full sticky top-[20px] bg-[#52bbab] fit-to-screen rounded-[20px] ${scrollPosition >= 10 ? "fulled-screen" : ""}`}
    >
      <div className="flex flex-col justify-between h-[37rem]">
        <div className="p-6 flex flex-col h-[38rem] w-full">
          {/* Dynamic Links */}
          {activeSidebar === "project"
            ? renderLinks(projectLinksArray)
            : renderLinks(investorLinksArray)}
        </div>

        <div className="">
          <div className="px-6">
            {/* Toggle Switch */}
            {user && user?.isProjectManager && (
              <div className="flex items-center link-button px-4 py-[18px] rounded-[10px]">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={activeSidebar === "investor"}
                    onChange={handleToggle}
                  />
                  <div className="w-[30px] h-[16px] mr-6 bg-[#fff] rounded-full transition-all"></div>
                  <span className="absolute left-1 top-[3px] w-[10px] h-[10px] bg-[#52bbab] rounded-full transition-transform peer-checked:translate-x-[12px]"></span>
                </label>
                <span className="text-white mr-2">
                  {activeSidebar === "project"
                    ? "Project View"
                    : "Investor View"}
                </span>
              </div>
            )}
          </div>
          <div className="p-6 w-full border-t-[1px] border-[#65d5c4]">
            <div className="flex flex-col w-full">
              {/* Static Links */}
              {renderLinks(StaticLinksArray)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
