import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import toast from "react-hot-toast";
import dayjs from "dayjs";

interface DataItem {
  _id: string;
  title: string;
  description: string;
  amount: number;
  timestamp: string;
}

const ViewDocumentation: React.FC<{ project: ProjectResponse | null }> = ({
  project,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [withdrawals, setWithdrawals] = useState<DataItem[]>([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{
    [key: string]: boolean;
  }>({});
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = withdrawals.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(withdrawals.length / itemsPerPage);
  const [loading, setLoading] = useState<boolean>(false);

  const formatDate = (timestamp: string): string => {
    const randomNumber = Math.floor(Math.random() * 1000) + 100;
    return dayjs(new Date(new Date().getTime() + randomNumber * 60000)).format(
      "YYYY-MM-DD : hh:mm"
    );
  };

  useEffect(() => {
    const fetchWithdrawalDocumentation = async () => {
      setLoading(true);
      try {
        const response = await ApiService({
          method: "POST",
          endpoint: apiPaths.getWithdrawalDocumentation,
          data: {
            projectManagerAddress: project?.contractAddress,
          },
          ledgerApi: true,
        });
        setWithdrawals(response.response);
        console.log("Withdrawal documentation response:", response);
        toast.success("Withdrawals for the project fetched successfully");
      } catch (error) {
        // setWithdrawals([
        //   {
        //     _id: "676aad79b808df2559494a3e",
        //     title: "Withdrawal 1",
        //     description:
        //       "Withdrawal descriptiondescriptio descriptiondescription descriptides criptiondescriptio on description",
        //     amount: 1000,
        //     timestamp: "1632960000",
        //   },
        //   {
        //     _id: "676aad79b808df2559494a3e",
        //     title: "Withdrawal 2",
        //     description:
        //       "Withdrawal descriptiondescriptio descriptiondescription descriptides criptiondescriptio on description",
        //     amount: 1000,
        //     timestamp: "1632960000",
        //   },
        // ]);
        console.error("Error fetching withdrawal documentation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawalDocumentation();
  }, [project]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const toggleDescription = (id: any) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="bg-white rounded-2xl shadow-lg p-6 lg:w-[65rem] max-w-[90rem] mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">
          Withdrawal Documentation
        </h2>
        {/* <a href="#" className="text-teal-500 hover:text-teal-600 transition-colors">
          View in Explorer
        </a> */}
        <a
          href={`https://testnet.snowtrace.io/address/${project?.contractAddress}/contract/43113/readContract?chainid=43113`}
          target="__blank"
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          View on blockchain explorer
        </a>
      </div>

      <div className="overflow-x-auto">
        {withdrawals.length === 0 ? (
          <div className="text-center py-6 text-gray-500">
            {loading ? "Loading..." : "No Withdrawals Yet"}
          </div>
        ) : (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                  Title
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                  Description
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {withdrawals &&
                currentItems.map((item, index) => (
                  <tr
                    key={item._id}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 text-sm text-gray-900">
                      {formatDate(item.timestamp)}
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-900">
                      {item.amount.toLocaleString()}
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-900">
                      {item.title}
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-900">
                      {expandedDescriptions[index]
                        ? item.description
                        : item.description.length > 40
                          ? `${item.description.substring(0, 40)}... `
                          : item.description}
                      {
                        <button
                          className="text-teal-500 hover:text-teal-600 transition-colors pl-1"
                          onClick={() => toggleDescription(index)}
                        >
                          {expandedDescriptions[index]
                            ? " See Less"
                            : " See More"}
                        </button>
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="flex justify-center items-center space-x-2 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          <ChevronLeft className="w-5 h-5 text-teal-500" />
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors
              ${
                currentPage === index + 1
                  ? "bg-teal-500 text-white"
                  : "text-gray-500 hover:bg-gray-100"
              }`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          <ChevronRight className="w-5 h-5 text-teal-500" />
        </button>
      </div>
    </div>
  );
};

export default ViewDocumentation;
