import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignupRequest, SignupResponse } from "./Signup.props";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { error } from "console";

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const signup = async (
    data: SignupRequest,
  ): Promise<SignupResponse | any> => {
    setLoading(true);

    try {
      const response: SignupResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.Signup,
        data,
      });

      if (response) {
        navigate("/login");
        toast.success("Signup Successfull");
        return response;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Failed to create account";
      toast.error(error.response.data.message);
      console.error("Signup error:", error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  // Password verification function
  const verifyPassword = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (password.trim() !== confirmPassword.trim()) {
      toast.error("Passwords do not match");
      return false;
    }
    return true;
  };

  const sendOtp = async (email: string) => {
    try {
      setLoading(true);
      const data = await ApiService({
        method: "POST",
        endpoint: apiPaths.sendOtp,
        data: { email: email },
      });
      return data;
    } catch (err: any) {
      const errorMessage = err?.response?.data?.message || "Failed to send OTP"
      toast.error(errorMessage);
      return null;
    }finally{
      setLoading(false);
    }
  };

  return { signup, verifyPassword, loading, sendOtp }; 
};
