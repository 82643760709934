import React, { useState, useEffect } from "react";
import axios from "axios";
// import { apiPaths } from "../../service/apiPaths";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { apiPaths } from "../../../service/apiPaths";
import RightIcon from "../../../images/Settings/right.png";

interface FileUploadResponse {
  files: any[];
  message: string;
}

interface FormData {
  projectId: string;
  title: string;
  description: string;
  receipt: string;
  amount: number;
}

interface ProjectResponse {
  _id: string;
  name: string;
}

const Withdrawals: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    projectId: "",
    title: "",
    description: "",
    receipt: "",
    amount: 0,
  });

  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const user = useSelector((store: any) => store.user.user);

  useEffect(() => {
    async function fetchProjects() {
      console.log("USER ID IN Project overview : ", user);
      if (user) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.getProjectsName}`,
          {
            withCredentials: true,
          },
        );

        const res = response.data.response;

        if (res && Array.isArray(res)) {
          setProjects(res);
        } else {
          // If no projects are fetched, fall back to dummy data
          // setAllProjects(dummyData);
          console.error(
            "Error fetching project details or no projects available.",
          );
        }
      }
      setIsLoading(false);
    }

    fetchProjects();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    // console.log(n)
    setFormData((prev) => ({
      ...prev,
      [name]: name === "amount" ? Number(value) : value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };

  const uploadFile = async () => {
    if (!file) return;

    setImageUploadLoader(true);
    let formDataInstance = new FormData();
    formDataInstance.append("files", file);

    try {
      const response = await axios.post<FileUploadResponse>(
        `${process.env.REACT_APP_API_BASE_URL}/${apiPaths.imageUpload}`,
        formDataInstance,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data.files[0];
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
      console.error("Error uploading file:", error);
      throw error;
    } finally {
      setImageUploadLoader(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const receipt = await uploadFile();
      const updatedFormData = { ...formData, receipt };

      console.log("Updated form data:", updatedFormData);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.submitWithdrawal,
        updatedFormData,
        {
          withCredentials: true,
        },
      );

      if (response.data.success) {
        toast.success("Withdrawal request submitted successfully");
      }
    } catch (error) {
      // console.log("Error submitting form:", error);
      if ((error as any)?.response?.data?.response) {
        toast.error((error as any)?.response.data.response);
      } else {
        toast.error("Error submitting form. Please try again.");
      }
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src='fulllogo.png' alt='logo' className="" />
      </div>
    );
  }

  return (
    <div className="bg-[#fff] rounded-[16px] p-6 min-h-full">
      <div className="flex items-center gap-2 font-semibold h-full">
        <Link to="/settings">
          <h1 className="mb-0 text-[20px]">Settings</h1>
        </Link>
        <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />

        <h1 className="mb-0 text-[20px] text-[#52bbab]">Withdrawals</h1>
      </div>
      <p className="text-[14px] text-[#9e9b9b] mb-10">
        Submit your withdrawal request by filling in the required details and
        uploading the receipt.
      </p>

      <div className="grid grid-cols-12 gap-x-12 gap-y-4">
        <div className="col-span-6">
          <p className="mb-2">Project</p>
          <select
            name="projectId"
            value={formData.projectId}
            onChange={handleChange}
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          >
            <option value="">Select a project</option>
            {projects.map((project, index) => (
              <option key={index} value={project._id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-span-6">
          <p className="mb-2">Withdrawal Title</p>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter Withdrawal Title"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        <div className="col-span-6">
          <p className="mb-2">Withdrawal Description</p>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter Withdrawal Description"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        <div className="col-span-6">
          <p className="mb-2">Receipt</p>
          <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
            <input type="file" className="hidden" onChange={handleFileChange} />
            <div className="flex justify-between items-center">
              <p className="text-gray-500 text-[14px]">
                {file ? file.name : "Upload your receipt"}
              </p>
            </div>
          </label>
        </div>

        <div className="col-span-6">
          <p className="mb-2">Amount</p>
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            placeholder="Enter Amount"
            className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          />
        </div>

        <div className="col-span-12 mt-6 flex justify-center">
          <button
            onClick={handleSubmit}
            disabled={isLoading || imageUploadLoader}
            className={`px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium ${isLoading ? "opacity-50" : ""}`}
          >
            {imageUploadLoader || isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Withdrawals;
