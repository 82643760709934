import { useState } from "react";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";

export const useGetActiveInvestors = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getActiveInvestors = async (
    page: string,
    searchTerm: string,
  ): Promise<any> => {
    setLoading(true);
    setError(null);

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.getActiveInvestors + `?page=${page}&limit=4`,
      });
      console.log(response);

      return response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { getActiveInvestors, loading, error };
};
