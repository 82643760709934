import React, { useEffect, useState } from "react";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import { useGetActiveInvestors } from "./useGetInvestors";

const Activeinvestor = () => {
  const { getActiveInvestors, loading, error } = useGetActiveInvestors();

  const [activeInvestors, setActiveInvestors] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  useEffect(() => {
    async function fetchActiveInvestors() {
      try {
        const resp = await getActiveInvestors(
          pagination.currentPage.toString(),
          searchTerm,
        );
        console.log(resp);
        if (resp) {
          setActiveInvestors(resp.response.docs || []);
          setPagination((prev) => ({
            ...prev,
            totalPages: resp.response.totalPages || 1,
            totalDocs: resp.response.totalDocs || 0,
            hasNextPage: resp.response.hasNextPage || false,
            hasPrevPage: resp.response.hasPrevPage || false,
          }));
        }
      } catch (error) {
        console.error("Failed to fetch active investors", error);
      }
    }

    fetchActiveInvestors();
  }, [pagination.currentPage]);

  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <p>Loading investors...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">
          {error || "Failed to load investors"}
        </span>
      </div>
    );
  }

  return (
    <div className="bg-[#fff] p-4 rounded-[16px] h-full">
      <h1 className="text-[20px] font-semibold">Active Investors</h1>
      <p className="text-[14px] text-[#9e9b9b] mt-2">
        View a list of active investors supporting your projects. Gain insights
        into their contributions and engagement, fostering connections and
        opportunities for collaboration.
      </p>
      {/* {Search Bar To Implement Search With Name Functionality Commented For now} */}
      {/* <div className="mt-4 mb-4 flex gap-2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search investors..."
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#56BBAC] focus:border-transparent"
                />
                <button
                    // onClick={handleSearch}
                    className="px-6 py-2 bg-[#56BBAC] text-white rounded-lg hover:bg-[#4aa799] transition-colors duration-200"
                >
                    Search
                </button>
            </div> */}
      {activeInvestors && activeInvestors.length > 0 ? (
        <div className="bg-white border rounded-lg overflow-hidden mt-4">
          <h1 className="ml-3 my-3 font-semibold">Active Investors</h1>
          <div className="overflow-x-auto">
            <table className="min-w-full shadow-sm">
              <thead>
                <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                  <th className="py-3 px-6 text-left">Sr. No.</th>
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Fiat</th>
                  <th className="py-3 px-6 text-left">Tokens</th>
                  <th className="py-3 px-6 text-left">Project</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {activeInvestors.map((project, index) => (
                  <tr
                    key={project.id}
                    className="border-b border-gray-200 hover:bg-gray-50"
                  >
                    <td className="py-3 px-6">
                      {(pagination.currentPage - 1) * 4 + index + 1}
                    </td>
                    <td className="py-3 px-6">
                      {project.investorName}
                    </td>
                    <td className="py-3 px-6">
                      {Math.round(project.fiat)}
                    </td>
                    <td className="py-3 px-6">{project.tokens}</td>
                    <td className="py-3 px-6">{project.projectName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="w-full h-[16rem] flex justify-center items-center">
          <p className="text-lg text-center">No Active Investor Found.</p>
        </div>
      )}

      <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
        <button
          onClick={handlePrevPage}
          disabled={!pagination.hasPrevPage}
          className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasPrevPage ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          <img className="h-[10px] w-[7px]" src={prevButton} alt="Previous" />
        </button>

        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
              pagination.currentPage === pageNumber
                ? "bg-[#56BBAC] text-[#fff]"
                : ""
            }`}
          >
            {pageNumber}
          </button>
        ))}

        <button
          onClick={handleNextPage}
          disabled={!pagination.hasNextPage}
          className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasNextPage ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          <img className="h-[10px] w-[7px]" src={nextButton} alt="Next" />
        </button>
      </div>
    </div>
  );
};

export default Activeinvestor;
