import React, { useState, useEffect, useRef } from "react";
import logo from "../../../src/images/logo/fulllogo.png";
import searchIcon from "../../../src/images/icons/search.png";
import notifyIcon from "../../../src/images/icons/notify.png";
import manImage from "../../../src/images/icons/man.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/appStore";
import { useProfile } from "../../page/Profile/useProfile";
import { setUser } from "../../redux/userSlice";
import { setIsLoading } from "../../redux/loaderSlice";

const Header = () => {
  const user = useSelector((store: RootState) => store.user.user);
  const { getProfile } = useProfile();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState<any>([]);
  const navigate = useNavigate();
  const resultsRef = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [dropdown, setDropdown] = useState(true);
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const isLoading = useSelector((store:RootState)=>store.loader.isLoading)

  useEffect(() => {
    async function fetchProfile() {
      const response = await getProfile();
      if (response) {
        console.log(response.profilePic);
        dispatch(setUser(response));
        setProfilePic(response.profilePic);
        setName(response.fullName);
        console.log(name, profilePic);
      } else {
        console.log("Error fetching profile");
      }
      setIsLoading(false);
    }

    fetchProfile();
  }, []);

  // Array of navigation links with names, descriptions, and URLs
  const links = [
    {
      name: "Explore",
      description:
        "Discover groundbreaking green initiatives transforming the world. Our platform enables project owners to list eco-friendly projects on the blockchain, while investors can explore, invest, and receive tokens as proof of their contribution to a sustainable future. Join the movement towards a greener tomorrow by supporting projects that align with your values.",
      url: "/",
      sectionId: "",
    },
    {
      name: "Dashboard",
      description:
        "Discover groundbreaking green initiatives transforming the world. Our platform enables project owners to list eco-friendly 909 projects on the blockchain, while investors can explore, invest, and receive tokens as proof of their contribution to a sustainable future. Join the movement towards a greener tomorrow by supporting projects that align with your values. Total Investment , Current Investment ,Total Returns ,Project Deliveries , Invested Projects ",
      url: "/dashboard",
    },
    {
      name: "Project Deliveries",
      description:
        "Monthly Patient Count , Monthly Admission Count , Monthly Discharge Count",
      url: "/dashboard",
      sectionId: "projectDeliveries",
    },
    {
      name: "Invested Projects",
      description: "investments",
      url: "/dashboard",
      sectionId: "investedProjects",
    },
    {
      name: "Market Overview",
      description:
        "Get a snapshot of the green project market, with insights into active projects, total investments, and token trends. Stay informed and make impact-driven investment decisions yash mishra helping shape a sustainable future. , Trade Volume on platform , #1 Trending Project ",
      url: "/marketoverview",
      sectionId: "",
    },
    {
      name: "Your Transactions",
      description:
        "View and track all your transactions in one place. Monitor investments, token transfers, and transaction history for complete transparency and control over your activities.",
      url: "/transaction",
      sectionId: "",
    },

    {
      name: "Profile",
      description: "Manage Your projects",
      url: "/profile",
      sectionId: "",
    },

    {
      name: "Settings",
      description:
        "Manage your account, preferences, and security settings. Customize your experience, update personal details, and control how you interact with the platform for a seamless and secure 1102 journey. , KYC , Notification , Manage Wallet , Become a Project Manager , Preferences ",
      url: "/settings",
      sectionId: "",
    },
    {
      name: "KYC",
      description: "Initiate a Kyc application",
      url: "/settings/kyc",
      sectionId: "",
    },
    {
      name: "Notifications",
      description:
        " In-App Notification , Email Notification ,Stay updated with real-time notifications. Get alerts on investments, project updates, mann jasmatia and important platform activity to ensure you never miss a key moment.",
      url: "/settings/notification",
      sectionId: "",
    },
    {
      name: "Manage Wallet",
      description:
        "Add a new Wallet , WalletConnect acts as a bridge, facilitating a secure and seamless connection between decentralized applications and various Web3 wallets. , Wallet Connect Integration , Customize your platform experience. Adjust notification settings, privacy options, and display preferences to suit your personal needs and enhance your journey.",
      url: "/settings/managewallet",
      sectionId: "",
    },
    {
      name: "Project Manager",
      description: "Manage Your projects",
      url: "/settings/project-manager",
      sectionId: "",
    },
    {
      name: "Preferences",
      description:
        "Customize your platform experience. Adjust notification settings, privacy options, and display preferences to suit your personal needs and enhance your journey. Time zone , time out , log out , timezone , timeout , logout ",
      url: "/settings/preference",
      sectionId: "",
    },
    {
      name: "Help and Support",
      description:
        "Need assistance? Visit our Help & Support page for FAQs, troubleshooting guides, and direct access to customer support. We're here to ensure your experience is smooth and hassle-free.",
      url: "/settings/help",
      sectionId: "",
    },
    // Add more links as needed
  ];

  if (user?.isProjectManager) {
    links.push({
      name: "Project Overview",
      description:
        "Explore detailed insights into your projects. View key metrics, investment status, and progress updates, all in one place to help you manage and showcase your green initiatives effectively. List a project ",
      url: "/projectoverview",
      sectionId: "",
    });
    links.push({
      name: "Active Investor",
      description:
        "View a list of active investors supporting your projects. Gain insights into their contributions and engagement, fostering connections and opportunities for collaboration.",
      url: "/activeinvestor",
      sectionId: "",
    });
    links.push({
      name: "Project Transactions",
      description:
        "View and track all your transactions in one place. Monitor investments, token transfers, and transaction history for complete transparency and control over your activities.",
      url: "/investortransaction",
      sectionId: "",
    });
    links.push({
      name: "List a Project",
      description: "List a project",
      url: "/listproject",
      sectionId: "",
    });
  }

  // Handle search input and filter results
  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    if (value) {
      // Filter links by name and description
      const filtered = links
        .filter(
          (link) =>
            link.name.toLowerCase().includes(value) ||
            link.description.toLowerCase().includes(value),
        )
        // Prioritize name matches over description matches
        .sort((a, b) => {
          const aNameMatch = a.name.toLowerCase().includes(value);
          const bNameMatch = b.name.toLowerCase().includes(value);
          if (aNameMatch && !bNameMatch) return -1;
          if (!aNameMatch && bNameMatch) return 1;
          return 0;
        });

      setFilteredResults(filtered);
    } else {
      setFilteredResults([]);
    }
  };

  // Handle navigation when a search result is clicked

  const handleResultClick = (url: any, sectionId: any) => {
    setQuery("");
    setFilteredResults([]);
    setActiveIndex(-1);
    navigate(url, { replace: true });

    setTimeout(() => {
      if (sectionId) {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, 100);
  };

  const handleKeyDown = (e: any) => {
    if (!filteredResults.length) return;

    switch (e.key) {
      case "ArrowDown":
        // Navigate down through the options
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredResults.length - 1),
        );
        break;
      case "ArrowUp":
        // Navigate up through the options
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        break;
      case "Enter":
        if (activeIndex >= 0) {
          const { url, sectionId } = filteredResults[activeIndex];
          handleResultClick(url, sectionId);
        }
        break;
      case "Escape":
        setFilteredResults([]);
        setActiveIndex(-1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (activeIndex >= 0 && resultsRef.current) {
      const activeItem = resultsRef.current.children[activeIndex];
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setDropdown(false);
        setFilteredResults([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return isLoading ? (
    <div className="flex items-center justify-center h-screen">
      <img src='fulllogo.png' alt='logo' className="" />
      </div>
  ) : (
    <>
      <header className="min-w-full-100 py-3 px-7 flex flex-row items-center justify-between h-[75px] bg-[#fff]">
        <div className="header-left-side flex flex-row gap-x-25">
          <div className="logo-box">
            <Link to="/">
              <img className="w-[200px]" src={logo} alt="" />
            </Link>
          </div>
          <div ref={searchRef} className="relative w-[450px]">
            {/* Search Bar */}
            <div className="searchBar relative bg-[#f3f3f3] w-full h-[52px] px-6 rounded-[25px]">
              <input
                type="text"
                className="h-full w-full bg-transparent focus:outline-none"
                placeholder="Search"
                value={query}
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
              />
              <div className="searchImg absolute top-1/2 -translate-y-1/2 right-[20px]">
                <img
                  className="h-[16px] opacity-40"
                  src={searchIcon}
                  alt="Search"
                />
              </div>
            </div>

            {/* Search Results */}
            {query && filteredResults.length > 0 && (
              <div
                ref={resultsRef}
                className="absolute bg-white w-full mt-2 rounded-lg shadow-lg z-10 max-h-[200px] overflow-y-auto"
              >
                {filteredResults.map((result: any, index: any) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleResultClick(result.url, result.sectionId)
                    }
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${
                      activeIndex === index ? "bg-gray-200" : ""
                    }`}
                  >
                    <h3 className="font-semibold text-gray-800">
                      {result.name}
                    </h3>
                  </div>
                ))}
              </div>
            )}

            {/* No Results Found */}
            {query && filteredResults.length === 0 && dropdown && (
              <div className="absolute bg-white w-full mt-2 rounded-lg shadow-lg z-10">
                <p className="px-4 py-2 text-gray-500">
                  No matching results found.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="header-right-side flex flex-row gap-4 items-center">
          <div className="notification-box h-[47px] w-[47px] flex items-center justify-center rounded-full bg-[#f3f3f3]">
            <img className="w-[21px]" src={notifyIcon} alt="" />
          </div>
          <Link className="rounded-full" to="/profile">
            <div className="notification-box h-[47px] w-[47px] flex items-center justify-center rounded-full bg-[#f3f3f3]">
              <img
                className="w-full rounded-full"
                src={profilePic || user?.profilePic || manImage}
                alt=""
              />
            </div>
          </Link>
          <p className="font-semibold">
            {name || user?.fullName || "NO NAME "}
          </p>
        </div>
      </header>
    </>
  );
};

export default Header;
