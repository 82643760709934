import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import downImage from "../../../images/icons/down.png";
import DashboardIcon from "../../../images/icons/dashboard.png";
import Explore1 from "../../../images/Explore/image1.png";
import TrandingButton from "../../../images/Explore/trading.png";
import forwordButton from "../../../images/Explore/forword.png";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import ProgressBar from "./ProgressBar";
import { useDashboard } from "../../Dashboard/useDashboard";
import { useSelector } from "react-redux";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import { dummyData } from "../../Dashboard/Components/dummyData";
import { RootState } from "../../../redux/appStore";

const Overview = () => {
  const { getProjectsByUser } = useDashboard();
  const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);
  const user = useSelector((store: RootState) => store?.user?.user);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(4);
  const [search, setSearch] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [docLengthList, setDocLengthList] = useState([1]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchProjects() {
      console.log("USER ID IN Project overview : ", user);
      if (user) {
        const response = await getProjectsByUser(
          String(user?._id),
          page,
          limit,
          search,
          "creator",
        );
        if (response && Array.isArray(response.docs)) {
          setAllProjects(response.docs);
          setTotalPages(response.totalPages);
        } else {
          // If no projects are fetched, fall back to dummy data
          // setAllProjects(dummyData);
          console.error(
            "Error fetching project details or no projects available.",
          );
        }
      }
      setIsLoading(false);
    }

    fetchProjects();
  }, [page, search, user]);

  const getApprovalColor = (status: string | undefined) => {
    switch (status) {
      case "approved":
        return "text-green-500";
      case "pending":
        return "text-orange-500";
      case "rejected":
        return "text-red-500";
      default:
        return "";
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        {/* <img src='fulllogo.png' alt='logo' className="" /> */}
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="rounded-[20px] bg-white h-full">
        <div className="flex flex-col h-full">
          <div className="p-6 border-b-[1px]">
            {/* Top Section: Heading and Actions */}
            <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
              {/* Left Section: Explore Heading */}
              <h1 className="text-[24px] font-semibold">Project Overview</h1>

              {/* Right Section: Search Field, Filter, and Dashboard Icon */}
              <div className="flex items-center gap-4">
                {/* Search Field */}
                <div className="flex items-center border border-[#56bbac] rounded-full overflow-hidden">
                  <input
                    type="text"
                    placeholder="Search Projects"
                    value={searchInput} // Bind local state to input
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="px-4 py-2 text-[14px] text-[#4a4a4a] outline-none flex-grow"
                  />
                  <button
                    onClick={() => setSearch(searchInput)}
                    className="px-4 py-2 bg-[#56bbac] text-white text-[14px] font-semibold hover:bg-[#479d94]"
                  >
                    Search
                  </button>
                </div>
                <Link
                  to="/listproject"
                  className="bg-[#56BBAC] text-[#fff] rounded-[20px] px-5 py-2"
                >
                  List a project
                </Link>

                {/* Filter Button */}
                <div className="h-[40px] w-auto px-4 rounded-[100px] gap-1 bg-[#fafafa] border flex items-center justify-center cursor-pointer hover:bg-[#f0f0f0]">
                  Filter
                  <img
                    className="w-[8px] h-[4px] ml-2"
                    src={downImage}
                    alt="Dropdown"
                  />
                </div>

                {/* Dashboard Icon Button */}
                <div className="h-[40px] w-[40px] rounded-[100px] gap-1 bg-[#fafafa] border flex items-center justify-center cursor-pointer hover:bg-[#f0f0f0]">
                  <img
                    className="h-[18px] w-[18px]"
                    src={DashboardIcon}
                    alt="Dashboard"
                  />
                </div>
              </div>
            </div>

            {/* Bottom Section: Description */}
            <p className="text-[14px] text-[#a4a4a4]">
              Explore detailed insights into your projects. View key metrics,
              investment status, and progress updates, all in one place to help
              you manage and showcase your green initiatives effectively.
            </p>
          </div>

          <div className="grid grid-cols-12 px-3 py-6">
            {/* Check if allProjects exists and has elements */}
            {allProjects && allProjects.length > 0 ? (
              allProjects.map((project) => (
                <div key={project._id} className="col-span-6 px-3 mt-4">
                  <div className="flex explore-card p-4 h-[165px] rounded-[20px] border bg-[#fafafa]">
                    <div className="img-box w-[200px]">
                      <img
                        className="w-[100px] h-[132px]"
                        src={project.logo || Explore1}
                        alt="Project Image"
                      />
                    </div>
                    <div className="content-box">
                      <div className="flex">
                        <div>
                          <h2 className="text-[16px] font-semibold">
                            {project.name}
                          </h2>
                          <p className="text-[10px] text-[#a4a4a4]">
                            {project.description.length > 100
                              ? project.description.slice(0, 95) + "..."
                              : project.description}
                          </p>
                          <p className="text-sm">
                            Approval Status :{" "}
                            <span
                              className={getApprovalColor(
                                project.approvalStatus,
                              )}
                            >
                              {project.approvalStatus}
                            </span>
                          </p>
                        </div>
                        <img
                          className="h-[27px] w-[80px]"
                          src={TrandingButton}
                          alt="Trending Button"
                        />
                      </div>
                      <div className="flex items-center justify-between mt-1">
                        <p className="text-[12px] text-[#a4a4a4]">
                          Raised: ${project.investmentRaised || 0}K
                        </p>
                        <p className="text-[12px] text-[#a4a4a4]">
                          {project.performance || 0}%
                        </p>
                      </div>
                      <ProgressBar
                        progress={project.performance || 0}
                        height={3}
                        color="#56BBAC"
                      />
                      <div className="flex justify-between mt-3">
                        <h2 className="text-[14px] text-[#56BBAC] font-semibold">
                          Goal: ${project.requiredInvestment}K
                        </h2>
                        <Link
                          to={`/exploredetails/${project._id}`}
                          className="button bg-[#56BBAC] flex items-center gap-2 w-[90px] h-[32px] justify-center rounded-[100px]"
                        >
                          <p className="text-[12px] text-white">Explore</p>
                          <img
                            className="h-[20px] w-[20px]"
                            src={forwordButton}
                            alt="Forward Button"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // Display this div when allProjects is empty
              <div className="col-span-12 flex items-center justify-center h-[200px]">
                <p className="text-center text-[18px] text-gray-500 font-semibold">
                  {search
                    ? "No Projects Found For The Given Search Parameter ! "
                    : "You haven't created any project yet , List a project to get started"}
                </p>
              </div>
            )}
          </div>

          {/* Pagination (optional) */}
          <div className="w-full flex text-center items-center justify-center mt-10 mx-auto">
            <div className="flex gap-2 mb-7 justify-center">
              {/* Previous Button */}
              <button
                disabled={page === 1}
                onClick={() => page > 1 && setPage(page - 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img
                  className={
                    "h-[10px] w-[7px]" + (page === 1 ? " opacity-40 " : "")
                  }
                  src={prevButton}
                  alt="Previous"
                />
              </button>

              {/* Page Buttons */}
              {(() => {
                // Calculate start and end of page numbers to display
                const maxVisibleButtons = 3;
                let startPage = Math.max(
                  1,
                  page - Math.floor(maxVisibleButtons / 2),
                );
                let endPage = Math.min(
                  Number(totalPages),
                  startPage + maxVisibleButtons - 1,
                );

                // Adjust startPage if there are fewer pages at the end
                if (endPage - startPage + 1 < maxVisibleButtons) {
                  startPage = Math.max(1, endPage - maxVisibleButtons + 1);
                }

                return Array.from(
                  { length: endPage - startPage + 1 },
                  (_, index) => startPage + index,
                ).map((pageNum) => (
                  <button
                    key={pageNum}
                    onClick={() => setPage(pageNum)}
                    className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
                      page === pageNum ? "bg-[#56BBAC] text-white" : ""
                    }`}
                  >
                    {pageNum}
                  </button>
                ));
              })()}

              {/* Next Button */}
              <button
                onClick={() => page < Number(totalPages) && setPage(page + 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img
                  className={
                    "h-[10px] w-[7px]" +
                    (page === Number(totalPages) ? " opacity-40 " : "")
                  }
                  src={nextButton}
                  alt="Next"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
