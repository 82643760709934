import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, // Add BarElement for Bar chart
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement, // Register BarElement
  PointElement,
  Title,
  Tooltip,
  Legend,
);

interface GraphProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  };
  options?: any;
}

const BarChart: React.FC<GraphProps> = ({ data, options }) => {
  if (!data || !data.labels || !data.datasets) {
    return <div>No data available</div>;
  }

  return (
    <>
      <Bar data={data} options={options} /> {/* Adding Bar chart here */}
    </>
  );
};

export default BarChart;
