import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import login1 from "../../../images/login/login1.png";
import facebook from "../../../images/login/google.png";
import google from "../../../images/login/google.png";
import apple from "../../../images/login/apple.png";
import LoginLogo from "../../../images/login/loginLogo.png";
import { useLogin } from "./useLogin";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "../../../redux/userSlice";
import { GoogleLogin } from "@react-oauth/google";
import AuthVerification from "../AuthVerification";

const Login = () => {
  const navigate = useNavigate();
  const { login, loading, error } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const storedActiveSidebar = localStorage.getItem("activeSidebar");

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission

    const credentials = {
      email: email,
      password: password,
    };
    console.log(credentials);

    const response = await login(credentials);
    if (response) {
      console.log("Login successful", response);

      dispatch(setIsLoggedIn(true));
      console.log("ACTIVE SIDEBAR : ", storedActiveSidebar);
      if (
        storedActiveSidebar === "investor" &&
        response.response?.isProjectManager
      ) {
        navigate("/projectoverview");
      } else {
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative lg:pt-0 pt-25">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt=""
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img className="lg:h-[90vh] max-w-full" src={login1} alt="" />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <h3 className="login-heading">Login</h3>
              <p className="login-subText">Login to access your account</p>

              <form className="mx-auto mt-10" onSubmit={handleLogin}>
                <div className="relative mb-5">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="john.doe@gmail.com"
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Email
                  </label>
                </div>
                <div className="relative mb-5">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Enter Your Password"
                    required
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Password
                  </label>
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  >
                    {showPassword ? (
                      <FaEye size={20} />
                    ) : (
                      <FaEyeSlash size={20} />
                    )}
                  </span>
                </div>

                {error && <p className="text-red-500">{error}</p>}
                <div className="flex items-start mb-5">
                  <div className="flex w-full justify-end">
                    <p
                      className="text-[#ff3d00] font-semibold cursor-pointer"
                      onClick={handleForgotPassword}
                    >
                      Forgot Password
                    </p>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg 
                  text-sm sm:w-auto px-5 py-2.5 text-center hover:scale-105"
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </form>

              <p className="text-center mt-5">
                Don’t have an account?
                <span
                  className="text-[var(--primary-color)] cursor-pointer"
                  onClick={handleSignup}
                >
                  Sign up
                </span>
              </p>

              <p className="two-side-line mt-7">Or login with</p>
              <AuthVerification></AuthVerification>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
