import React from "react";
import RightIcon from "../../../images/Settings/right.png";
import RightIcon2 from "../../../images/Settings/right2.png";
import KycImage from "../../../images/Settings/kyc.png";
import { Link } from "react-router-dom";

const Kyc = () => {
  return (
    <>
      <div className="bg-[#fff] rounded-[16px] min-h-full">
        <div className="p-6 border-b-2">
          <div className="flex items-center gap-2 font-semibold">
            <Link to="/settings">
              <h1 className="mb-0 text-[20px]">Settings</h1>
            </Link>
            <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
            <h1 className="mb-0 text-[20px] text-[#52bbab]">KYC</h1>
          </div>
          <p className="text-[14px] text-[#9e9b9b]">
            Customize your platform experience. Adjust notification settings,
            privacy options, and display preferences to suit your personal needs
            and enhance your journey.
          </p>
        </div>
        <div className="p-24 flex flex-col items-center justify-center">
          <img className="h-[125px] w-[113px]" src={KycImage} alt="" />
          <h1 className="font-medium text-[22px] my-4">KYC Pending</h1>
          <p className="w-2/3 text-center text-[14px] text-[#9e9b9b]">
            Your KYC verification is pending, please click above to submit it.
            All features on the platform will be blocked until your KYC
            verification is completed.
          </p>
          <Link
            className="w-[400px] flex justify-center rounded-[12px] mt-6 h-[50px] bg-[#52bbab]"
            to="#"
          >
            <div className="flex items-center gap-3 ">
              <p className="text-[18px] text-[#fff]">Initiate your KYC</p>
              <img className="h-[20px] w-[20px]" src={RightIcon2} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Kyc;
