import { useState } from "react";
import { ProjectResponse } from "../Dashboard/Dashboard.props";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import toast from "react-hot-toast";

export const useExplore = () => {
  const [loading, setLoading] = useState(false);

  const getProjectDetailsById = async (
    id: string,
  ): Promise<ProjectResponse | null> => {
    setLoading(true);

    try {
      const response = await ApiService({
        method: "GET",

        endpoint: apiPaths.ListProject + "/" + id,
      });

      if (response) {
        console.log(response);
        return response.response;
      } else {
        toast("Failed to fetch project");
        return null;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Error while fetching projects";
      toast.error(errorMessage);
      console.error("Project error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getProjects = async (search: string, page: number, limit: number) => {
    setLoading(true);

    var queryString = "?page=" + page + "&limit=" + limit;

    queryString += search ? "&search=" + search : "";

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.ListProject + queryString,
      });

      if (response) {
        return response.response;
      } else {
        toast("Failed to fetch all projects");
        return null;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Error while fetching all projects";
      toast.error(errorMessage);
      console.error("Project error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { getProjectDetailsById, getProjects, loading };
};
