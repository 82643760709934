// src/hooks/useLogin.ts
import { useState } from "react";
import { LoginRequest, LoginResponse } from "./Login.props";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { setIsLoggedIn, setUser } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const login = async (
    credentials: LoginRequest,
  ): Promise<LoginResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: LoginResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.Login,
        data: credentials,
      });

      console.log("LOGIN RESPONSE : ");
      console.log(response.response);

      dispatch(setUser(response.response));
      dispatch(setIsLoggedIn(true));

      // Handle successful login
      toast.success("Login successful!", { duration: 5000 });
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message || "Error Occured");

      // console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};
