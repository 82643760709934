import React, { useCallback } from "react";
import facebook from "../../images/login/facebook.png";
import google from "../../images/login/google.png";
import apple from "../../images/login/apple.png";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useAuthVerification } from "./useAuthVerification";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice";
import { setIsLoggedIn } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

interface AuthVerificationProps {
  onGoogleSuccess?: (response: CredentialResponse) => void;
  onGoogleError?: (error: Error) => void;
}

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
}

const IconButton: React.FC<ButtonProps> = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full"
    type="button"
  >
    {children}
  </button>
);

const AuthVerification: React.FC<AuthVerificationProps> = ({
  onGoogleSuccess,
  onGoogleError,
}) => {
  const { googleAuthLogin } = useAuthVerification();
  const storedActiveSidebar = localStorage.getItem("activeSidebar");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoogleLoginSuccess = async (
    credentialResponse: CredentialResponse,
  ) => {
    const { credential } = credentialResponse;
    if (!credential) {
      toast.error("Google Login Failed");
      return;
    }

    const googleAuthResponse = await googleAuthLogin(credential);
    if (googleAuthResponse) {
      dispatch(setUser(googleAuthResponse.user));
      dispatch(setIsLoggedIn(true));
      if (
        storedActiveSidebar === "investor" &&
        googleAuthResponse?.user?.isProjectManager
      ) {
        navigate("/projectoverview");
      }
      toast.success("Google Auth Success");
    } else {
      toast.error("Google Auth Failed");
    }
  };

  const handleGoogleLoginError = useCallback((): void => {
    const error = new Error("Google Login Failed");
    console.error(error);
    onGoogleError?.(error);
  }, [onGoogleError]);

  return (
    <div>
      <div className="grid grid-cols-12 mt-4">
        {/* Facebook Login Button */}
        <div className="col-span-4 px-2">
          <IconButton>
            <img width={24} src={facebook} alt="Facebook Login" />
          </IconButton>
        </div>

        {/* Google Login Button */}
        <div className="col-span-4 px-2">
          <div className="relative">
            <IconButton>
              <img width={24} src={google} alt="Google Login" />
            </IconButton>
            <div className="absolute inset-0">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
                width="100%"
                useOneTap={false}
                auto_select={false}
                theme="outline"
                size="large"
                text="signin_with"
                shape="rectangular"
                locale="en"
                containerProps={{
                  className: "w-full h-full opacity-0",
                }}
              />
            </div>
          </div>
        </div>

        {/* Apple Login Button */}
        <div className="col-span-4 px-2">
          <IconButton>
            <img width={24} src={apple} alt="Apple Login" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default AuthVerification;
