import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import fulllogo from "../../images/logo/fulllogo.png";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#1E293B",
      fontFamily: "Inter, sans-serif",
      "::placeholder": {
        color: "#94A3B8",
      },
    },
    invalid: {
      color: "#EF4444",
    },
  },
};

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  const { projectName, projectId, amount, tokens } = location.state || {};

  console.log(amount);

  // Mock details for amount, tokens, and project name

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (!stripe || !elements) return;

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card element not found");
      setLoading(false);
      return;
    }

    try {
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.paymentIntent,
        data: JSON.stringify({
          amount: amount,
          currencyId: "670fbc9b9aedcfcb302862d4",
          projectId: projectId,
          tokens: tokens,
        }),
      });

      console.log(response);
      const clientSecret = response.response.clientSecret.client_secret;
      console.log(clientSecret);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      console.log(result);

      if (result.error) {
        setError(result.error.message || "Payment failed");
      } else if (result.paymentIntent?.status === "succeeded") {
        setPaymentResult("Payment successful!");
      }
    } catch (err) {
      console.log(err);
      setError("An unexpected error occurred");
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white shadow-xl rounded-3xl max-w-lg w-full p-8 space-y-8">
        {/* Header */}
        <div className="text-center">
          <img src={fulllogo} alt="Logo" className="h-12 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-800">Checkout</h2>
          <p className="text-sm text-gray-500">Complete your payment below</p>
        </div>

        {/* Key Details */}
        <div className="space-y-4">
          <div className="bg-gray-100 rounded-xl p-4 flex items-center justify-between">
            <span className="text-gray-600">Amount to Pay</span>
            <span className="font-semibold text-gray-900">€{amount}</span>
          </div>
          <div className="bg-gray-100 rounded-xl p-4 flex items-center justify-between">
            <span className="text-gray-600">Tokens</span>
            <span className="font-semibold text-gray-900">{tokens}</span>
          </div>
          <div className="bg-gray-100 rounded-xl p-4 flex items-center justify-between">
            <span className="text-gray-600">Project Name</span>
            <span className="font-semibold text-gray-900">{projectName}</span>
          </div>
        </div>

        {/* Payment Form */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Card Details
            </label>
            <div className="mt-2 border border-gray-300 rounded-xl p-4 bg-gray-50">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-600 text-sm">
              {error}
            </div>
          )}

          {paymentResult && (
            <div className="bg-green-50 border border-green-200 rounded-xl p-4 text-green-600 text-sm">
              {paymentResult}
            </div>
          )}

          <button
            type="submit"
            disabled={!stripe || loading}
            className="w-full py-3 bg-green-600 text-white font-semibold rounded-xl shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-green-300 disabled:cursor-not-allowed"
          >
            {loading ? "Processing..." : "Pay Now"}
          </button>
        </form>

        {/* Footer */}
        <div className="text-center text-sm text-gray-500 mt-6">
          Payments are secure and encrypted
        </div>
      </div>
    </div>
  );
};

const StripePayment: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripePayment;
