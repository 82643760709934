import { useState } from "react";
import { useDispatch } from "react-redux";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import toast from "react-hot-toast";
import axios from "axios";
import qs from "qs";

const useGetWithdrawals = () => {
  const [loading, setLoading] = useState(false);

  const getWithdrawalDetailsById = async (id: string | undefined) => {
    try {
      setLoading(true);
      const response: any = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getWithdrawals}/${id}`,
      });
      if (response.success) {
        console.log(response.response);
        return response.response;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { loading, getWithdrawalDetailsById };
};

export default useGetWithdrawals;
