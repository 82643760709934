import { useState } from "react";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";

export const useGetInvestorTransactions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getInvestorsTransaction = async (page: string): Promise<any> => {
    setLoading(true);
    setError(null);

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.getInvestorTransactions + `?page=${page}&limit=5`,
      });

      console.log(response);

      return response.response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getProjectMangerTransaction = async (page: string): Promise<any> => {
    setLoading(true);
    setError(null);

    try {
      const response = await ApiService({
        method: "GET",
        endpoint:
          apiPaths.getProjectManagerTransactions + `?page=${page}&limit=5`,
      });

      console.log(response);

      return response.response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { getInvestorsTransaction, loading, error, getProjectMangerTransaction };
};
