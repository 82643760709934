// src/hooks/useLogin.ts
import { useState } from "react";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import { ListProjectRequest, ListProjectResponse } from "./listProject.props";

export const useListProject = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const listProject = async (data: any): Promise<any> => {
    setLoading(true);
    setError(null);

    try {
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.CreateProject,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { listProject, loading, error };
};
