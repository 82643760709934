// src/components/Authentication/VerifyCode/VerifyCode.tsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import LoginLogo from "../../../images/login/loginLogo.png";
import VerifyImage from "../../../images/login/verifyimg.png";
import useVerify from "./useVerify"; // Import the useVerify hook
import { resendOtp } from "./useVerify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { VerifyRefreshToken } from "../VerifyRefreshToken";
import { setUserDetails } from "../../../redux/signupSlice";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const VerifyCode = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const email = useSelector((store: any) => store.signup.email);
  const { otp, setOtp, loading, error, handleSubmit } = useVerify();
  const location = useLocation();
  const isSignup = location.pathname.includes("/verify/signup");
  const dispatch = useDispatch();

  const handleLogin = () => {
    navigate("/login"); // Navigate to Login screen
  };
  console.log(email)

  useEffect(() => {
    if (!email) {
      navigate("/login");
      return;
    }
  }, [email, navigate]);

  if (!email) {
    return null; 
  }

  const handleSetPassword = async () => {
    if(!otp) {
      toast.error("Please enter OTP");
      return;
    }
    if(!/^\d+$/.test(otp)) {
      toast.error("Invalid OTP");
      return;
    }
  
    const isVerified = await handleSubmit(email, otp);
    if (isVerified) {
      dispatch(setUserDetails({ otp: otp }));
      if (isSignup) {
        navigate("/newpassword/create");
      } else {
        navigate("/newpassword/forget");
      }
    }
  };

  const resendHandler = async () => {
    const data = await resendOtp(email);
    console.log(data);
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative lg:pt-0 pt-25">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt=""
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img className="lg:h-[90vh] max-w-full" src={VerifyImage} alt="" />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <div
                className="flex items-center mb-2 gap-1 cursor-pointer"
                onClick={handleLogin}
              >
                <IoChevronBack fontSize={22} />
                <p className="mb-0 font-medium">Back To Login</p>
              </div>
              <h3 className="forgot-heading">Verify Code</h3>
              <p className="login-subText mt-5">
                An authentication code has been sent to your email.
              </p>

              <form className="mx-auto mt-10" onSubmit={handleSetPassword}>
                <div className="relative mb-5">
                  <input
                    type="text" // OTP input field
                    id="otp"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="" // Keep placeholder empty
                    value={otp} // Bind OTP state
                    onChange={(e) => setOtp(e.target.value)} // Update OTP state
                    required
                  />
                  <label
                    htmlFor="otp"
                    className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Enter OTP
                  </label>
                </div>

                {error && <p className="text-red-500">{error}</p>}

                <div
                  className="flex items-center w-full my-4 gap-1"
                  onClick={resendHandler}
                >
                  <p>Didn’t receive a code? </p>
                  <p className="text-[#ff3d00] font-semibold hover:cursor-pointer">
                    Resend
                  </p>
                </div>

                <button
                  type="button"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                  onClick={handleSetPassword}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? "Verifying..." : "Verify"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
