import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import LoginLogo from "../../../images/login/loginLogo.png";
import ForgotImage from "../../../images/login/forgotimg.png";
import facebook from "../../../images/login/facebook.png";
import google from "../../../images/login/google.png";
import apple from "../../../images/login/apple.png";
import useForgot from "./useForgot";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { email, setEmail, loading, error, handleSubmit } = useForgot(); // Destructure from the hook

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="min-h-screen  flex items-center justify-center relative lg:pt-0 pt-25 ">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt=""
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img className="lg:h-[90vh] max-w-full" src={ForgotImage} alt="" />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <div
                className="flex items-center mb-2 gap-1 cursor-pointer"
                onClick={handleLogin}
              >
                <IoChevronBack fontSize={22} />
                <p className="mb-0 font-medium">Back To Login</p>
              </div>
              <h3 className="forgot-heading">Forgot your password?</h3>
              <p className="login-subText mt-5">
                Don’t worry, happens to all of us. Enter your email below to
                recover your password
              </p>
              <form className="mx-auto mt-10" onSubmit={handleSubmit}>
                <div className="relative mb-5">
                  <input
                    type="email"
                    id="email"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="john.doe@gmail.com"
                    value={email} // Bind email state to input field
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Email
                  </label>
                </div>

                <button
                  type="submit"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </form>
              {error && <p className="text-red-500 mt-2">{error}</p>}
              <p className="two-side-line my-7">Or login with</p>
              <div className="grid grid-cols-12 mt-4">
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={facebook} alt="" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={google} alt="" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={apple} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
