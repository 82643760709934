import React from "react";

interface ProgressBarProps {
  progress: number; // Progress as a percentage (0 to 100)
  height?: number; // Optional height of the progress bar
  color?: string; // Optional color of the progress bar
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  height = 20,
  color = "#4caf50",
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        backgroundColor: "#e0e0e0",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: `${progress}%`,
          height: "100%",
          backgroundColor: color,
          borderRadius: "10px",
          transition: "width 0.25s ease-in-out",
        }}
      />
    </div>
  );
};

export default ProgressBar;
