import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";

export const useAuthVerification = () => {
  const googleAuthLogin = async (googleAuthToken: string) => {
    try {
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.GoogleAuth,
        data: { googleAuthToken },
      });

      console.log(response);
      return response;
    } catch (error) {
      return null;
    }
  };

  return { googleAuthLogin };
};
